import React from "react";
import { useTheme } from "@mui/system";

const cyan = {
  50: "#E9F8FC",
  100: "#BDEBF4",
  200: "#99D8E5",
  300: "#66BACC",
  400: "#1F94AD",
  500: "#0D5463",
  600: "#094855",
  700: "#063C47",
  800: "#043039",
  900: "#022127",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

function useIsDarkMode() {
  const theme = useTheme();
  return theme.palette.mode === "dark";
}

const Styles = () => {
  const isDarkMode = useIsDarkMode();

  return (
    <style>
      {`
        .CustomInputIntroduction {
          width:100%;
          min-width: 395px;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.5;
          padding: 13.5px 12px; 
          border-radius: 8px 0px 0px 8px; 
          color: ${isDarkMode ? grey[300] : grey[900]};

          border-radius: 8px 0px 0px 8px;
          border: 1px solid var(--gray-200, #CBD1D6);
          background: var(--Colors-Base-white, #FFF);
          box-shadow: 0px 2px 4px ${
            isDarkMode ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
          };

          &:hover {
            border-color: ${cyan[400]};
          }

          &:focus {
            border-color: ${cyan[400]};
            box-shadow: 0 0 0 3px ${isDarkMode ? cyan[600] : cyan[200]};
          }

          &:focus-visible {
            outline: 0;
          }
        }
      `}
    </style>
  );
};

export default Styles;
