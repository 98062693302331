import Styles from "../../data/inputStyles";
import "../../assest/css/input.scss";
import React from "react";
import { Input } from "@mui/base/Input";
import Button from "@mui/material/Button";

const InputFiled = () => {
  return (
    <React.Fragment>
      <div className="email-input">
        <Input
          slotProps={{ input: { className: "CustomInputIntroduction" } }}
          aria-label="Demo input"
          placeholder="Enter your email"
        />
        <Button variant="contained" className="submit-button">
          Submit
        </Button>
      </div>
      <Styles />
    </React.Fragment>
  );
};

export default InputFiled;
