import React from "react";
import Counter from "./Counter";
import "../../assest/css/timeline.scss";
import useCurrentPath from "../../hooks/useCurrentPath";
const Timing = () => {
  const CurrentPath = useCurrentPath();
  return (
    <div className="row">
      <div
        className={`timing  ${
          CurrentPath === "/comingsoonV2" && "time-line-div-modify"
        }`}
      >
        <div className="col-lg-12">
          <ul>
            <li>
              <strong className="counter">
                <Counter start={4} end={13} />
              </strong>
              <span>Date</span>
            </li>
            <li>
              <strong className="counter">
                <Counter start={1} end={6} />
              </strong>
              <span>Month</span>
            </li>
            <li>
              <strong className="counter">
                <Counter start={2015} end={2024} />
              </strong>
              <span>Year</span>
            </li>
            {/* <!-- <li><strong className="">45</strong><span>second</span></li> --> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Timing;
