import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import ComingSoon from "./pages/comingsoonone/ComingSoon";
import ComingSoonTwo from "./pages/comingsoontwo/ComingSoonTwo";
function App() {
  return (
    // comingsoon
    // <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route path="/comingsoonV1" element={<ComingSoon />} />
      <Route path="/comingsoonV2" element={<ComingSoonTwo />} />
    </Routes>
    // {/* </Suspense> */}
  );
}

export default App;
