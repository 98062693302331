import FaceBook from "../assest/Images/SociallMediaSvgIcons/Facebook.svg";
import X from "../assest/Images/SociallMediaSvgIcons/X.svg";
import Instagram from "../assest/Images/SociallMediaSvgIcons/Instagram.svg";
import YouTube from "../assest/Images/SociallMediaSvgIcons/YouTube.svg";
import Linkdin from "../assest/Images/SociallMediaSvgIcons/Linkdin.svg";
export const socilaMedia = [
  {
    id: 1,
    link: "#",
    // title: "Facebook",
    icon: FaceBook,
  },
  {
    id: 2,
    link: "#",
    // titel: "Twitter",
    icon: X,
  },
  {
    id: 3,
    link: "#",
    // title: "Pinterest",
    icon: Instagram,
  },
  {
    id: 4,
    link: "#",
    // title: "Email",
    icon: YouTube,
  },
  {
    id: 5,
    link: "#",
    // title: "WhatsApp",
    icon: Linkdin,
  },
];
