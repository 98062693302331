import React from "react";
import { useSpring, animated } from "react-spring";

const Counter = ({ start, end }) => {
  const props = useSpring({
    from: { number: start },
    to: { number: end },
    config: { duration: 1000 },
  });

  return <animated.h1>{props.number.to((n) => Math.ceil(n))}</animated.h1>;
};
export default Counter;
