import React from "react";
import Logo from "../../components/ui/Logo";
import SocialMediaIcon from "../../components/ui/SocialMediaIcon";
import TimeLine from "../../components/form/TimeLine";
import "../../assest/css/comingsoontwo.scss";
import useCurrentPath from "../../hooks/useCurrentPath";
import CopyRight from "../../components/copyright/CopyRight";
import InputFiled from "../../components/form/Input";

const ComingSoonTwo = () => {
  const currentPath = useCurrentPath();
  return (
    <section className="comingsoontwo-banner">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Logo />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="comingsoon-main">
              <TimeLine />
              <div
                className={`inner-content ${
                  currentPath === "/comingsoonV2" ? "inner-content-modify" : ""
                }`}
              >
                <h4>Be Ready, We are</h4>
                <h1>Coming Soon...</h1>
              </div>
              <div
                className={`f-content ${
                  currentPath === "/comingsoonV2" ? "f-content-modify" : ""
                }`}
              >
                <p>
                  "Discover thw wonderful island of Santorini.....adipiscing
                  elit. Nam vel sem eros. Phasellus suscipit nulla est, id
                  rhoncus nibh finibus nec. Mauris malesuada lacus vel sem
                  pellentesque imperdiet. Aenean sed iaculis eros, ac iaculis
                  quam. Praesent molestie leo eget commodo auctor. Quisque
                  semper ex facilisis, tincidunt lorem sed, semper nisl. Sed
                  congue mollis consectetur. Donec non massa odio."
                </p>
              </div>
              <div className="input-area">
                <InputFiled />
              </div>
              <div className="social-media-div">
                <SocialMediaIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`copy-right-div ${
          currentPath === "/comingsoonV2" ? "copy-right-div-modify" : ""
        }`}
      >
        <CopyRight />
      </div>
    </section>
  );
};
export default ComingSoonTwo;
