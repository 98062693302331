import React from "react";
import Logo from "../../components/ui/Logo";
import "../../assest/css/comingsoon.scss";
import TimeLine from "../../components/form/TimeLine";
import SocialMediaIcon from "../../components/ui/SocialMediaIcon";
import Img from "../../assest/Images/LogoImg.png";
import InputFiled from "../../components/form/Input";
import CopyRight from "../../components/copyright/CopyRight";

const ComingSoon = () => {
  return (
    <section className="banner">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <Logo />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7 order-2 order-lg-1">
            <div className="main-content">
              <div className="inner-content">
                <h4>Be Ready, We are</h4>
                <h1>Coming Soon...</h1>
                <p className="mini-content">
                  By Promoting all the destination services and business, in
                  line with the values of the local community, Go2Greece creates
                  a very powerful destination management and prmotion tool set
                </p>
              </div>
              <div className="input-area">
                <InputFiled />
              </div>
              <div className="social-media-div">
                <h5>FOLLOW US</h5>
                <SocialMediaIcon />
              </div>
              <div className="f-content">
                <p>
                  "Discover thw wonderful island of Santorini.....adipiscing
                  elit. Nam vel sem eros. Phasellus suscipit nulla est, id
                  rhoncus nibh finibus nec. Mauris malesuada lacus vel sem
                  pellentesque imperdiet. Aenean sed iaculis eros, ac iaculis
                  quam. Praesent molestie leo eget commodo auctor. Quisque
                  semper ex facilisis, tincidunt lorem sed, semper nisl. Sed
                  congue mollis consectetur. Donec non massa odio."
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 order-1 order-lg-">
            <div className="timeline-main">
              <TimeLine />
              <div className="side-content-img">
                <img src={Img} alt="hotel img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right-div">
        <CopyRight />
      </div>
    </section>
  );
};

export default ComingSoon;
