import React from "react";
import { socilaMedia } from "../../utils/constant";
import "../../assest/css/socilamediaicon.scss";
const SocialMediaIcon = () => {
  return (
    <div className="social-media-icons">
      {socilaMedia.map((socialMedia) => (
        <a key={socialMedia.id} href={socialMedia.link}>
          <div className="icon-wraper">
            <img
              src={socialMedia.icon}
              alt={`Social Media Icon ${socialMedia.id}`}
              className="social-media-icon"
            />
          </div>
        </a>
      ))}
    </div>
  );
};

export default SocialMediaIcon;
